import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import PostLink from "../components/post-link";
import HeroHeader from "../components/heroHeader";

const IndexPage = ({
  data: {
    site,
    allMarkdownRemark: { edges },
  },
}) => {
  const Posts = edges.map((edge) => (
    <PostLink key={edge.node.id} post={edge.node} />
  ));

  return (
    <Layout mainLink="Willkommen bei WoodLooksGood">
      <Helmet>
        <title>{site.siteMetadata.title}</title>
        <meta name="description" content={site.siteMetadata.description} />
      </Helmet>
      <HeroHeader />
      {/* <h2>Blog Posts &darr;</h2> */}
      <div className="social-links">
        <a
          href="tel://00492122239800"
          className="social-link"
        >
          <img src={"/phone.svg"}></img> 0212 / 22 39 800
        </a>
        <a
          href="https://www.instagram.com/woodlooksgood.de/"
          className="social-link"
        >
          <img src={"/IG_Glyph_Fill.png"}></img> Instagram
        </a>
        <a
          href="https://www.facebook.com/WoodLooksGood-364780626931494"
          className="social-link"
        >
          <img src={"/f_logo_RGB-Blue_250.png"}></img> Facebook
        </a>
      </div>
      <h2 style={{ marginTop: "16px" }}>Projekte</h2>
      <div className="grids">{Posts}</div>
    </Layout>
  );
};

export default IndexPage;
export const pageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
            thumbnail
          }
        }
      }
    }
  }
`;
