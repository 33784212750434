import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";
export default () => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
        site {
          siteMetadata {
            home {
              title
              description
            }
          }
        }
      }
    `}
    render={(data) => (
      <div className="hero-header">
        {/* <div className="headline">{data.site.siteMetadata.home.title}</div>
          <div
            className="primary-content"
            dangerouslySetInnerHTML={{
              __html: data.site.siteMetadata.home.description,
            }}
          /> */}
        <div className="logo-container">
          <img src={"/logo.svg"} alt="WoodLooksGood Logo" />
        </div>
        {/* <Link to="/kontakt" className="button -primary">
          Kontakt &rarr;
        </Link> */}
        {/* <Link to="/kontakt" className="button -primary">
          Kontakt
        </Link> */}
      </div>
    )}
  />
);
